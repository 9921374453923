// ============================================================================
// HoldingUserService
// -----------------------
// HoldingUser module related services
// ============================================================================
// -------
// Imports
// -------
import { RequiredArguments } from '@/helpers/methods'
import API from '@/apis/HoldingUserApi'
import { store } from '@/store'

// ---------
// Internals
// ---------
const Private = {
    requests: {
        holding: {
            user: {
                catalogTheme: {
                    create: function (holdingId, userId, catalogThemeId) {
                        RequiredArguments({ holdingId, userId, catalogThemeId })
                        return API.holding.user.catalogTheme.create(holdingId, userId, catalogThemeId)
                    },
                    delete: function (holdingId, userId, catalogThemeId) {
                        RequiredArguments({ holdingId, userId, catalogThemeId })
                        return API.holding.user.catalogTheme.delete(holdingId, userId, catalogThemeId)
                    }
                },
                themes: {
                    read: function (holdingId, userId, params = {}) {
                        RequiredArguments({ holdingId, userId })
                        return API.holding.user.themes.read(holdingId, userId, params)
                    }
                },
                vendor: {
                    user: {
                        theme: {
                            create: function (holdingId, vendorId, userId, themeId) {
                                RequiredArguments({ holdingId, vendorId, userId, themeId })
                                return API.holding.vendor.user.theme.create(holdingId, vendorId, userId, themeId)
                            },
                            delete: function (holdingId, vendorId, userId, themeId) {
                                RequiredArguments({ holdingId, vendorId, userId, themeId })
                                return API.holding.vendor.user.theme.create(holdingId, vendorId, userId, themeId)
                            }
                        },
                        create: function (holdingId, vendorId, userId, params = {}) {
                            RequiredArguments({holdingId, vendorId, userId})
                            return API.holding.vendor.user.create(holdingId, vendorId, userId, params)
                        },
                        delete: function (holdingId, vendorId, userId) {
                            RequiredArguments({holdingId, vendorId, userId})
                            return API.holding.vendor.user.delete(holdingId, vendorId, userId)
                        }
                    }
                },
                vendors: {
                    read: function (holdingId, userId, params = {}) {
                        RequiredArguments({ holdingId, userId })
                        return API.holding.user.vendors.read(holdingId, userId, params)
                    },
                    create: function (holdingId, userId, params = {}) {
                        RequiredArguments({ holdingId, userId })
                        return API.holding.user.vendors.create(holdingId, userId, params)
                    },
                    delete: function (holdingId, userId, params = {}) {
                        RequiredArguments({ holdingId, userId })
                        return API.holding.user.vendors.delete(holdingId, userId, params)
                    }
                },
                accountingFirms: {
                    read: function (holdingId, userId, params) {
                        RequiredArguments({ holdingId, userId })
                        return API.holding.user.accountingFirms.read(holdingId, userId, params)
                    }
                },
                accountingFirm: {
                    create: function (holdingId, accountingFirmId, userId, params = null, data = null) {
                        RequiredArguments({ holdingId, accountingFirmId, userId })
                        return API.holding.user.accountingFirm.create(holdingId, accountingFirmId, userId, params, data)
                    },
                    delete: function (holdingId, accountingFirmId, userId, params = null) {
                        RequiredArguments({holdingId, accountingFirmId, userId})
                        return API.holding.user.accountingFirm.delete(holdingId, accountingFirmId, userId, params)
                    },
                    update: function (holdingId, accountingFirmId, userId, params = null, data=null) {
                        RequiredArguments({ holdingId, accountingFirmId, userId })
                        return API.holding.user.accountingFirm.update(holdingId, accountingFirmId, userId, params, data)
                    },
                    
                },
                read: function (holdingId, userId, params = null) {
                    RequiredArguments({holdingId, userId})
                    return API.holding.user.read(holdingId, userId, params)
                },
                invite: function (holdingId, userId) {
                    RequiredArguments({holdingId, userId})
                    return API.holding.user.invite(holdingId, userId)
                },
                resetPassword: function (holdingId, userId) {
                    RequiredArguments({holdingId, userId})
                    return API.holding.user.resetPassword(holdingId, userId)
                },
                update: function (holdingId, userId, data = null, params = null) {
                    RequiredArguments({holdingId, userId, data})
                    return API.holding.user.update(holdingId, userId, data, params)
                },
                delete: function (holdingId, userId, params = null) {
                    RequiredArguments({holdingId, userId})
                    return API.holding.user.delete(holdingId, userId, params)
                }
            },
            users: {
                read: function (holdingId, params) {
                    RequiredArguments({holdingId, params})
                    return API.holding.users.read(holdingId, params)
                },
                update: function (holdingId, userId, data = null, params = null) {
                    RequiredArguments({holdingId, userId, data})
                    return Private.requests.holding.users.read(holdingId, userId, data, params)
                },
                create: function (holdingId, data) {
                    RequiredArguments({ holdingId, data })
                    return API.holding.users.create(holdingId, data)
                }
            },
            vendor: {
                user: {
                    theme: {
                        create: function (holdingId, vendorId, userId, themeId) {
                            RequiredArguments({ holdingId, vendorId, userId, themeId })
                            return API.holding.vendor.user.theme.create(holdingId, vendorId, userId, themeId)
                        },
                        delete: function (holdingId, vendorId, userId, themeId) {
                            RequiredArguments({ holdingId, vendorId, userId, themeId })
                            return API.holding.vendor.user.theme.delete(holdingId, vendorId, userId, themeId)
                        }
                    }
                }
            },
        },
        users: {
            read: function (params = {}) {
                return API.users.read(params)
            }
        }
    },
    service: {
        holding: {
            user: {
                read: function (holdingId, userId, params = null) {
                    return Private.requests.holding.user.read(holdingId, userId, params).then(data => {
                        let user = data.user
                        user.is_holding_admin = data.role ? data.role.key === 'admin' : false
                        user.totalHoldingVendorLinkedWithUser = data.totalHoldingVendorLinkedWithUser
                        user.totalHoldingUserVendor = data.totalHoldingUserVendor
                        return Private.store.holding.user.set(user).then(() => {
                            return user
                        })
                    })
                },
                invite: function (holdingId, userId){
                    RequiredArguments({holdingId, userId})
                    return Private.requests.holding.user.invite(holdingId, userId)
                },
                resetPassword: function (holdingId, userId){
                    RequiredArguments({holdingId, userId})
                    return Private.requests.holding.user.resetPassword(holdingId, userId)
                },
                update: function (holdingId, userId, data = null, params = null) {
                    RequiredArguments({holdingId, userId, data})
                    return Private.requests.holding.user.update(holdingId, userId, data, params)
                },
                delete: function (holdingId, userId, params = null) {
                    RequiredArguments({holdingId, userId})
                    return Private.requests.holding.user.delete(holdingId, userId, params)
                }
            },
            users : {
                read: function (holdingId, params = null) {
                    RequiredArguments({holdingId})
                    return Private.store.holding.user.reset().then(() => {
                        return Private.requests.holding.users.read(holdingId, params).then(users => {
                            users.data.map(user => { 
                                user.is_holding_admin = user.role_key === 'admin'
                                return user
                            })
                            return users
    
                        })
                    })
                },
                create: function (holdingId, data = {}, params = null) {
                    RequiredArguments({ holdingId })
                    return Private.requests.holding.users.create(holdingId, data, params)
                }
            }
        },
        users: {
            read: function (params = {}) {
                return Private.requests.users.read(params)
            }
        }
    },
    store: {
        holding: {
            user: {
                set: function (user) {
                    RequiredArguments({ user: user })
                    return store.dispatch('holdingUsers/setEditedUser', user)
                },
                get: function () {
                    return store.getters['holdingUsers/getEditedUser']
                },
                reset: function () {
                    return store.dispatch('holdingUsers/reset')
                }
            }
        }
    }
}
// -------
// Exports
// -------
export default {
    findHoldingUser: Private.service.holding.user.read,
    updateHoldingUser: Private.service.holding.user.update,
    deleteHoldingUser: Private.service.holding.user.delete,
    sendMail: Private.service.holding.user.invite,
    getUserByEmail: Private.service.users.read,
    resetPassword: Private.service.holding.user.resetPassword,
    createHoldingUsers: Private.service.holding.users.create,
    getHoldingUsers: Private.service.holding.users.read,

    assignCatalogTheme: Private.requests.holding.user.catalogTheme.create,
    assignTheme: Private.requests.holding.vendor.user.theme.create,
    denyCatalogTheme: Private.requests.holding.user.catalogTheme.delete,
    denyTheme: Private.requests.holding.vendor.user.theme.delete,
    listThemes: Private.requests.holding.user.themes.read,

    listVendors: Private.requests.holding.user.vendors.read,
    createUserVendor: Private.requests.holding.user.vendor.user.create,
    deleteUserVendor: Private.requests.holding.user.vendor.user.delete,
    createAllUserVendor: Private.requests.holding.user.vendors.create,
    deleteAllUserVendor: Private.requests.holding.user.vendors.delete,
    listAccountingFirms: Private.requests.holding.user.accountingFirms.read,
    assignAccountingFirm: Private.requests.holding.user.accountingFirm.create,
    unassignAccountingFirm: Private.requests.holding.user.accountingFirm.delete,
    updateRole: Private.requests.holding.user.accountingFirm.update,
}