// ============================================================================
// HoldingUserApi
// -------------------
// HoldingUser api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    holding: {
        user: {
            catalogTheme: {
                create: function (holdingId, userId, catalogThemeId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, userId, catalogThemeId })
                    return Backend.POST(URLS.api.holdings.users.catalogThemes.uri, [holdingId, userId, catalogThemeId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                delete: function (holdingId, userId, catalogThemeId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, userId, catalogThemeId })
                    return Backend.DELETE(URLS.api.holdings.users.catalogThemes.uri, [holdingId, userId, catalogThemeId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            themes: {
                read: function (holdingId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, userId })
                    return Backend.GET(URLS.api.holdings.users.themes.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            vendors: {
                read: function (holdingId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, userId })
                    return Backend.GET(URLS.api.holdings.users.vendors.uri, [ holdingId, userId ], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                create: function (holdingId, userId, params = null, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    return Backend.POST(URLS.api.holdings.users.vendors.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                delete: function (holdingId, userId, params = null, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    return Backend.DELETE(URLS.api.holdings.users.vendors.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
            },
            accountingFirms: {
                read: function (holdingId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, userId })
                    return Backend.GET(URLS.api.holdings.users.accountingFirms.uri, [holdingId, userId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            accountingFirm: {
                create: function (holdingId, accountingFirmId, userId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId, userId, data })
                    return Backend.POST(URLS.api.holdings.accountingFirms.users.uri, [holdingId, accountingFirmId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                delete: function (holdingId, accountingFirmId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId, userId })
                    return Backend.DELETE(URLS.api.holdings.accountingFirms.users.uri, [holdingId, accountingFirmId, userId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                update: function (holdingId, accountingFirmId, userId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId, userId, data })
                    return Backend.PATCH(URLS.api.holdings.accountingFirms.users.uri, [holdingId, accountingFirmId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
            },
            read: function (holdingId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId})
                return Backend.GET(URLS.api.holdings.users.uri, [holdingId, userId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            invite: function (holdingId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId})
                return Backend.POST(URLS.api.holdings.users.sendMail.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            resetPassword: function (holdingId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false ) {
                RequiredArguments({holdingId, userId})
                return Backend.POST(URLS.api.holdings.users.resetPassword.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            update: function (holdingId, userId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId, data})
                return Backend.PATCH(URLS.api.holdings.users.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            delete: function (holdingId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId})
                return Backend.DELETE(URLS.api.holdings.users.uri, [holdingId, userId], params, null, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        users: {
            read: function (holdingId, params = { 'page': 1 }, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId})
                return Backend.GET(URLS.api.holdings.users.uri, [holdingId], params , doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            create: function (holdingId, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.POST(URLS.api.holdings.users.uri, [holdingId], null, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        vendor: {
            user: {
                theme: {
                    create: function (holdingId, vendorId, userId, themeId, params = null, data = null, doCancelPreviousRequest  = false, doCancelAllOtherRequests = false) {
                        RequiredArguments({ holdingId, vendorId, userId, themeId })
                        return Backend.POST(URLS.api.holdings.vendors.users.themes.uri, [holdingId, vendorId, userId, themeId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                    },
                    delete: function (holdingId, vendorId, userId, themeId, params = null, data = null, doCancelPreviousRequest  = false, doCancelAllOtherRequests = false) {
                        RequiredArguments({ holdingId, vendorId, userId, themeId })
                        return Backend.DELETE(URLS.api.holdings.vendors.users.themes.uri, [holdingId, vendorId, userId, themeId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                    }
                },
                create: function (holdingId, vendorId, userId, params = null, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    return Backend.POST(URLS.api.holdings.vendors.users.uri, [holdingId, vendorId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
                delete: function (holdingId, vendorId, userId, params = null, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    return Backend.DELETE(URLS.api.holdings.vendors.users.uri, [holdingId, vendorId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            }  
        },
    },
    users: {
        read: function (params = {}, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            return Backend.GET(URLS.api.users.uri, [], params , doCancelPreviousRequest, doCancelAllOtherRequests)
        }
    }
}