<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height column>
		<v-layout v-if="noWorkspaces" fill-height row>
			<w-flex offset-xs1 xs4 mr-2>
				<w-layout align-center column fill-height justify-center>
					<w-flex v-t="'holding.team-manager.vendors.no_vendors_available_title'" display-1 mb-4 shrink />
					<w-flex v-t="'holding.team-manager.vendors.no_vendors_available_text'" mb-2 shrink subheading />
					<w-btn @click="goToPage(workspacesListPage)">{{ $t('actions.click_here') }}</w-btn>
				</w-layout>
			</w-flex>
			<w-flex xs6 ml-2>
				<w-layout align-center fill-height>
					<NoWorkspaceAvailableImage height="80%" />
				</w-layout>
			</w-flex>
		</v-layout>
		<template v-else-if="!noWorkspaces">
			<w-flex shrink>
				<v-toolbar dense>
					<w-search-input v-model="search" pr-2 :placeholder="$t('holding.team-manager.vendors.search')" />
					<w-btn flat icon="fas fa-filter" mini small @click="showFilterMenu">{{ $t('actions.filter') }}</w-btn>
					<v-menu
						v-model="displayFilterMenu"
						absolute
						:close-on-content-click="false"
						offset-y
						:position-x="filterMenuCoordinates.x"
						:position-y="filterMenuCoordinates.y"
					>
						<v-list>
							<v-list-tile>
								<w-list-tile-content style="min-height: 60px">
									<WorkspaceFilter v-model="selectedAccountingFirms" :items="workspaces" :loading="loading" />
								</w-list-tile-content>
							</v-list-tile>
						</v-list>
					</v-menu>
					<w-spacer />
					<w-btn :disabled="noVendor || updatingAccess || userHasAccessToAllVendors" @click="updateAllAccess()">{{ $t('holding.admin-team.access_all_vendors') }}</w-btn>
				</v-toolbar>
			</w-flex>
			<w-flex shrink mt-2 mb-2 ml-4>
				<w-text-info :text="$t('holding.admin-team.access_to_all_vendors_themes_info')" />
			</w-flex>
			<w-flex shrink scroll-y ml-4 mr-4>
				<w-layout justify-center row>
					<v-flex scroll-y>
						<v-data-table
							class="elevation-1"
							:headers="tableHeaders"
							:items="vendors"
							:loading="loading || updatingAccess"
							:no-data-text="noDataText"
							:pagination.sync="pagination"
							:rows-per-page-items="rowsPerPageItems"
							:total-items="pagination.totalItems"
						>
							<template v-slot:headers="{ headers }">
								<tr>
									<th
										v-for="(header, index) in headers.filter(header => !header.last && header.text)"
										:key="index"
										:class="{
											active: pagination.sortBy == header.value,
											asc: !pagination.descending,
											column: true,
											desc: pagination.descending,
											sortable: header.sortable,
											'text-xs-center': header.align == 'center',
											'text-xs-left': header.align != 'center'
										}"
										@click="changeSort(header)"
									>
										<v-icon v-if="header.sortable" small>arrow_upward</v-icon>
										{{ header.text }}
									</th>
									<th>
										<v-checkbox
											color="primary"
											:disabled="vendors.length == 0"
											false-value="none"
											hide-details
											:indeterminate="columnState == 'some'"
											:input-value="columnState"
											true-value="all"
											@change="updateAccessForDisplayedVendors($event)"
										/>
									</th>
									<th>
										{{ headers[headers.length - 1].text }}
									</th>
								</tr>
							</template>
							<template v-slot:items="{ item: vendor }">
								<tr>
									<td v-html="$highlightMatching(vendor.company, search)" />
									<td v-html="$highlightMatching(vendor.accounting_firm_name, search)" />
									<td v-html="$highlightMatching(vendor.siret, search)" />
									<td v-html="$highlightMatching(vendor.client_code, search)" />
									<td>
										<w-checkbox v-model="vendor.hasAccess" :disabled="updatingAccess" @input="updateAccess(vendor)" />
									</td>
									<td>
										<SelectWithChips
											v-model="vendor.userThemes"
											:item-clearable="false"
											item-text="title"
											item-value="id"
											:items="vendor.themes"
											:label="$t('themes.documents.themes')"
											list-with-chips
											multiple
											return-object
											style="width: 100%"
											:disabled="!vendor.hasAccess || updatingAccess"
											@input="updateThemes(vendor)"
										>
										</SelectWithChips>
									</td>
								</tr>
							</template>
						</v-data-table>
					</v-flex>
				</w-layout>
			</w-flex>
		</template>
	</w-layout>
</template>

<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'

export default {
	name: 'HoldingUserVendors',
	components: {
		NoWorkspaceAvailableImage: () => ({
			component: import('@/components/Holding/NoWorkspaceAvailableImage')
		}),
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		}),
		WorkspaceFilter: () => ({
			component: import('@/components/Holding/Filters/WorkspaceFilter')
		})
	},
	mixins: [HoldingUserModuleGuard],
	inject: ['holdingContext'],
	data: function () {
		return {
			displayFilterMenu: false,
			filterMenuCoordinates: {
				x: 0,
				y: 0
			},
			noWorkspaces: false,
			loading: false,
			pagination: {
				page: 1,
				rowsPerPage: 10,
				totalItems: 0,
				sortBy: 'company',
				descending: false
			},
			rowsPerPageItems: [10, 20, 50, 100],
			search: '',
			searchTimeout: null,
			selectedAccountingFirms: [],
			tableHeaders: [
				{ align: 'left', text: this.$t('customer.general.company'), value: 'company', sortable: true },
				{ align: 'left', text: this.$t('holding.admin-team.workspace'), value: 'accounting_firm_name', sortable: true },
				{ align: 'left', text: this.$t('siret'), value: 'siret', sortable: true },
				{ align: 'left', text: this.$t('holding.admin-team.code'), value: 'client_code', sortable: true },
				{ text: '' },
				{ align: 'left', text: this.$t('holding.admin-team.themes'), value: 'themes', sortable: false, last: true }
			],
			updatingAccess: false,
			vendors: [],
			workspaces: [],
			workspacesListPage: { route: 'holding-team-user-accounting-firm-assignment' }
		}
	},
	computed: {
		allAccountingFirmsSelected: function () {
			return this.selectedAccountingFirms.length === this.workspaces.length
		},
		columnState: function () {
			if (this.vendors.every(vendor => !vendor.hasAccess)) {
				return 'none'
			}
			if (this.vendors.some(vendor => !vendor.hasAccess)) {
				return 'some'
			}
			return 'all'
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		noDataText: function () {
			let result = null
			if (this.loading) {
				result = this.$t('customers.loading-progress')
			} else if (Array.isArray(this.selectedAccountingFirms) && this.selectedAccountingFirms.length == 0) {
				result = this.$t('customers.no-accounting-firm-id-selected')
			} else if (this.search) {
				result = this.$t('customers.no-data-found-for-search', { search: this.search })
			}else {
				result = this.$t('customers.no-data-found')
			}
			return result
		},
		userHasAccessToAllVendors: function () {
			return this.$store.getters['holdingUsers/userHasAccessToAllVendors']
		},
		noVendor: function () {
			return this.vendors.length === 0
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler: function (newVal, oldVal) {
				if (oldVal && newVal && (newVal.page !== oldVal.page || newVal.rowsPerPage !== oldVal.rowsPerPage)) {
					this.loadVendors()
				}
			},
			immediate: true
		},
		search: {
			handler: function (newVal, oldVal) {
				if ((!oldVal && newVal) || (oldVal && newVal) || (oldVal && !newVal)) {
					clearTimeout(this.searchTimeout)
					this.resetPagination()
					this.searchTimeout = setTimeout(() => {
						this.loadVendors()
					}, 500)
				}
			}
		},
		selectedAccountingFirms: {
			handler: function (newVal) {
				this.resetPagination()
				if (newVal.length == 0) {
					this.resetVendors()
				} else {
					this.loadVendors()
				}
			}
		}
	},
	mounted: function () {
		this.loadWorkspaces()
	},
	methods: {
		changeSort: function (header) {
			if (!header.sortable) {
				return
			}
			const column = header.value
			if (this.pagination.sortBy != column) {
				this.pagination.sortBy = column
				this.pagination.descending = false
			} else if (this.pagination.descending) {
				this.pagination.sortBy = null
			} else {
				this.pagination.descending = true
			}
			this.pagination.page = 1
			this.search = ''
			this.loadVendors()
		},
		getSortingAlgorithm: function () {
			if (!this.pagination.sortBy) {
				return null
			}
			return (this.pagination.descending ? '-' : '+') + this.pagination.sortBy
		},
		goToPage: function (page) {
			const currentParams = this.$route.params
			this.appService.goTo({
				name: page.route,
				params: currentParams
			})
		},
		loadVendors: function () {
			let params = {
				page: this.pagination.page,
				search: this.search,
				rowsPerPage: this.pagination.rowsPerPage,
				with_themes: true,
				with_all_vendors: true,
				accounting_firms: this.selectedAccountingFirms
			}
			const sortingAlgorithm = this.getSortingAlgorithm()
			if (sortingAlgorithm) {
				params.sort = sortingAlgorithm
			}
			this.loading = true
			this.service.listVendors(this.holdingId, this.holdingUser.id, params).then(vendors => {
				this.noVendors = vendors.length == 0 && this.firstLoad
				this.dataAlreadyLoaded = true
				this.loading = false
				this.vendors = vendors.data
				this.vendors.map(vendor => (vendor.originalUserThemes = vendor.userThemes))
				this.pagination.page = vendors.pagination.currentPage
				this.pagination.rowsPerPage = vendors.pagination.perPage
				this.pagination.totalItems = vendors.pagination.total
			})
		},
		loadWorkspaces: function () {
			this.service.listAccountingFirms(this.holdingId, this.holdingUser.id).then(workspaces => {
				this.workspaces = workspaces
				this.noWorkspaces = this.workspaces.length == 0
			})
		},
		resetVendors: function () {
			this.pagination.page = 1
			this.pagination.totalItems = 0
			this.vendors = []
		},
		showFilterMenu: function (clickEvent) {
			this.filterMenuCoordinates.x = clickEvent.clientX
			this.filterMenuCoordinates.y = clickEvent.clientY
			this.displayFilterMenu = true
		},
		resetPagination: function () {
			this.pagination.page = 1
			this.pagination.rowsPerPage = 10
			this.pagination.totalItems = 0
		},
		updateAccess: function (vendor) {
			if (vendor.hasAccess) {
				const params = { affect_all_themes: true }
				this.service.createUserVendor(this.holdingId, vendor.id, this.holdingUser.id, params).then(() => {
					vendor.themes.forEach(aTheme => {
						vendor.userThemes.push(aTheme)
					})
					this.holdingUser.totalHoldingVendorLinkedWithUser = this.holdingUser.totalHoldingVendorLinkedWithUser + 1
				})
			} else {
				this.service.deleteUserVendor(this.holdingId, vendor.id, this.holdingUser.id).then(() => {
					vendor.userThemes = []
					vendor.originalUserThemes = []
					this.holdingUser.totalHoldingVendorLinkedWithUser = this.holdingUser.totalHoldingVendorLinkedWithUser - 1
				})
			}
		},
		updateAccessForDisplayedVendors: function (state) {
			state = state == 'none' ? false : true
			this.vendors
				.filter(vendor => vendor.hasAccess != state)
				.forEach(vendor => {
					vendor.hasAccess = state
					this.updateAccess(vendor)
				})
			const message = state
				? this.$t('holding.admin-team.access_to_all_displayed_vendors_added')
				: this.$t('holding.admin-team.access_to_all_displayed_vendors_removed')
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
		},
		updateAllAccess: function () {
			this.updatingAccess = true
			if (this.userHasAccessToAllVendors) {
				return
			}
			this.service
					.createAllUserVendor(this.holdingId, this.holdingUser.id)
					.then(() => {
						this.vendors.forEach(vendor => {
							vendor.hasAccess = true
							vendor.themes.forEach(aTheme => {
								vendor.userThemes.push(aTheme)
							})
						})
						this.holdingUser.totalHoldingVendorLinkedWithUser = this.holdingUser.totalHoldingUserVendor
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.access_to_all_vendors'))
					})
					.finally(() => {
						this.updatingAccess = false
					})
		},
		updateThemes: function (vendor) {
			let themeToChange = null
			let themeUpdated = false
			vendor.userThemes.forEach(currentTheme => {
				themeToChange = vendor.originalUserThemes.find(originalTheme => originalTheme.id == currentTheme.id)
				if (!themeToChange) {
					this.service.assignTheme(this.holdingId, vendor.id, this.holdingUser.id, currentTheme.id).then(() => {
						vendor.originalUserThemes = vendor.userThemes
					})
					themeUpdated = true
				}
			})
			if (!themeUpdated) {
				vendor.originalUserThemes.forEach(originalTheme => {
					themeToChange = vendor.userThemes.find(currentTheme => originalTheme.id == currentTheme.id)
					if (!themeToChange) {
						this.service.denyTheme(this.holdingId, vendor.id, this.holdingUser.id, originalTheme.id).then(() => {
							vendor.originalUserThemes = vendor.userThemes
						})
					}
				})
			}
		}
	}
}
</script>
